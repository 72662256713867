<template>
	<div>
		<van-nav-bar
		        title='超時'
		        left-arrow
		        fixed
		        class="qjc-nav-bar"
		        @click-left="$router.replace('/payment/0')"
		/>
		
		<div class="overtime qjc-fts-32">
			<img src="../../assets/images/overtime.png" alt="超時">
			<p>網絡連接超時，請稍後再試</p>
			<span @click="$router.replace('/payment/0')" class="qjc-c-primary">返回</span>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar } from "vant"
	Vue.use(NavBar);
	
	export default{
		name: "overtime",
		data (){
			return {}
		}
	}
</script>

<style lang="scss" scoped>
	.overtime{
		padding-top: 2.88rem;
		
		img{
			width: 4.4rem;
			height: 3.43rem;
		}
		p{
			color: #9A9FB4;
			margin: 0.88rem auto 0.33rem;
		}
	}
</style>
